const above = 1;
const below = -1;

// Material UI default z-index
export const default_mobile_stepper = 1000;
export const default_fab = 1050;
export const default_speed_dial = 1050;
export const default_app_bar = 1100;
export const default_drawer = 1200;
export const default_modal = 1300;
export const default_snackbar = 1400;
export const default_tooltip = 1500;

// Custom z-index
export const zLayoutInsideLoading = 1000;
export const zPullToRefresh = 100;
export const zLeftMenuDrawerBottomView = above + default_drawer;
export const zAppBarClose = below + default_drawer;
export const zAppBarOpen = above + default_drawer;
export const zAppLoader = above + default_tooltip;
export const zAppAlert = above + zAppLoader;
export const zBackDropLoader = above + zAppAlert;
export const zAppBottomSheet = 1301; // default DialogMUI is 1300

// Custom z-index for notification
export const zNotificationBase = above + zAppAlert;
export const zNotificationBottomView = above + zNotificationBase;
export const zNotificationClearAll = zNotificationBottomView;
export const zNotificationItemClearIcon = zNotificationBottomView;
