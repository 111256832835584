import React, {styled} from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import {zAppLoader} from 'constants/ZIndexNumber';
const LoaderWrapper = styled('div')(({theme}) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: zAppLoader,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

export default Loader;
