import React, {lazy} from 'react';
import './index.css';
import Loadable from './components/loading/loadable/Loadable';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-spring-bottom-sheet/dist/style.css';
import {createRoot} from 'react-dom/client';
import 'react-spring-bottom-sheet/dist/style.css';

const App = Loadable(lazy(() => import('./feature/mainApp/App')));

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container);
root.render(<App />);
